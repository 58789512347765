import React, { useState, useContext, useEffect } from "react"
import { Form, Row, Col, Button, Spinner } from "react-bootstrap"
import { toast } from "react-toastify"
import axios from "axios"

//** Configs */
import { SERVER_URL } from "../../configs";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import { loadUserTasks } from "../../redux/actions/user";
import { loadTicketGroups } from "../../redux/actions/ticketGroups";
import { changeTicketStatus } from "../../services/ticket";
import { createTicketStepConversation } from "../../utils/msteams";
import { getFormattedDateTimeStrV3 } from '../../utils';
import { USER_ROLES } from "../../constants";
import { AuthContext } from "../../contexts/JWTContext";

//** Components */
import NotesAndMemos from "./NotesAndMemos";
import ConfirmationModal from "../../components/jobs/confirmationModal"
import CustomCard from "../../components/CustomCard"
import Popconfirm from "../../components/Popconfirm";
import useJobDetails from "./useJobDetails";
import moment from 'moment';
import Reviews from "./reviews";

const SubStep = ({
    reloadThreadList,
    getNextStepId,
    step,
    allStepsCompleted,
    updateTicket,
    currentStep,
    handleTicketStepChange,
    setStepsActiveTabKey,
    ticketInfo,
    activateNextStepModal,
    setActivateNextStepModal,
    showAssignFinalizationModal,
}) => {
    const users = useAppSelector(state => state.appData.users)
    const usersMap = useAppSelector(state => state.appData.usersMap);
    const ticketStepTypes = useAppSelector(state => state.appData.ticketStepTypes)
    const pageSize = useAppSelector(state => state.ticketGroups.pageSize);
    const filters = useAppSelector(state => state.search.filters);

    const [chatConversationId, setChatConversationId] = useState();
    const [createConversationLoading, setCreateConversationLoading] = useState(false);
    const [stepAssigneeModal, setStepAssigneeModal] = useState(false)
    const [disableCSB, disableCompleteStepButton] = useState(false)
    const [userId, setUserId] = useState("")
    const [applyToAllPortfolio, setApplyToAllPortfolio] = useState(false);
    const [confirmModalShow, setConfirmModalShow] = useState(false);
    const dispatch = useAppDispatch()
    const { questionsByStepId, onQuestionsChange } = useJobDetails();
    const questions = questionsByStepId[step.id] || [];

    useEffect(() => {
        setUserId(step.assignedToId || "")
    }, [step.assignedToId])

    const handleAnswerBlur = (questionId, index) => {
        saveAnswer(questionId, questions[index].answer)
    }

    const handleAnswerChange = (questionId, value, index) => {
        const _questions = [...questions]
        questions[index].answer = String(value)

        if (questions[index].ticketStepQuestion.type === 1)
            saveAnswer(questionId, String(value))

        onQuestionsChange(_questions, step.id);
    }

    const createConversation = async () => {
        setCreateConversationLoading(true);
        const ticketStepType = ticketStepTypes.find(item => item.id === step.ticketStepTypeId)
        const data = await createTicketStepConversation(step.id, ticketStepType.name);
        if (data != null) {
            setChatConversationId(data);
            reloadThreadList(data);
        }
    }

    const saveAnswer = (questionId, answer) => {
        axios.post(`${SERVER_URL}/api/TicketStepQuestion/UpdateQuestionAnswer`, {
            ticketStepQuestionAnswerId: questionId,
            answer
        }).then(() => {
        }).catch((err) => {
            console.error(err)
        })
    }

    const markStepAsCompleted = async (id) => {
        disableCompleteStepButton(true)
        try {
            await axios.post(`${SERVER_URL}/api/TicketStep/MarkTicketStepAsCompleted?ticketStepId=${id}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (ticketInfo.ticketSteps.every(step => !!step.completedDate || step.id === id)) {
                // set ticket status to Completed
                await changeTicketStatus({ ticketId: ticketInfo.id, ticketStatusTypeId: 5 });
            }
            toast.success("Ticket Step updated successfully !")
            updateTicket(() => {
                let _id = getNextStepId(id)
                if (_id) {
                    setActivateNextStepModal(_id)
                } else {
                    showAssignFinalizationModal();
                }
                disableCompleteStepButton(false)
                dispatch(loadUserTasks());
                dispatch(loadTicketGroups(0, pageSize, filters))
            })
        } catch (err) {
            disableCompleteStepButton(false)
            console.error(err)
            toast.error("Failed to update ticket step, please contact administrator !!!")
        }
    }

    const changeStepUser = () => {

        if (applyToAllPortfolio) {
            if (!window.confirm("Are you sure you want to change ticket step assignee to all portfolio tickets?")) {
                return;
            }
        }

        axios.post(`${SERVER_URL}/api/TicketStep/ChangeTicketStepAssignee`, {
            ticketStepId: step.id,
            assignedToId: userId,
            applyToAllPortfolio
        }).then(() => {
            setStepAssigneeModal(false)
            toast.success("Ticket Step updated successfully !")
            updateTicket()
            dispatch(loadTicketGroups(0, pageSize, filters))
        }).catch((err) => {
            console.error(err)
            toast.error("Failed to update ticket step, please contact administrator !!!")
        })
    }

    const handleActivateNextStepModal = (state) => {
        if (!state) {
            setActivateNextStepModal(0)
        }
    }

    const currentStepActive = !allStepsCompleted && currentStep && ((step.ticketStepTypeId === currentStep.ticketStepTypeId) || !step.completedDate);

    return (
        <div className="mx-1 h-100">
            <Popconfirm
                show={confirmModalShow}
                title={'Activating this step will change ticket status to "Active"'}
                okText="Activate"
                cancelText="Cancel"
                onOk={() => {
                    setConfirmModalShow(false);
                    handleTicketStepChange("", step.ticketStepTypeId);
                }}
                onCancel={() => setConfirmModalShow(false)}
            />
            <CustomCard className="h-100" bodyClassName="h-100 d-flex flex-column">
                <ConfirmationModal
                    title="Change Ticket Step Assignee"
                    show={stepAssigneeModal}
                    setShow={setStepAssigneeModal}
                    handleConfirm={changeStepUser}
                >
                    <h5>
                        Users
                        <Form.Select name="userId" value={userId} onChange={(e) => setUserId(e.target.value)}>
                            <option value="">Select User</option>
                            {users.filter(u => u.active).map((user, index) => (
                                <option key={index} value={user.id}>{`${user.firstName} ${user.lastName}`}</option>
                            ))}
                        </Form.Select>

                        
                    </h5>

                    {ticketInfo && ticketInfo.portfolioId &&
                        <div>
                            <Form.Check
                                id="applyToAllPortfolioTickets"
                                type="checkbox"
                                label="Apply to all portfolio tickets"
                                checked={applyToAllPortfolio}
                                onChange={(e) => setApplyToAllPortfolio(e.target.checked)}
                            />
                        </div>
                    }
                </ConfirmationModal>
                {activateNextStepModal === step.ticketStepTypeId && (
                    <ConfirmationModal
                        title="Activate Next Step"
                        show
                        setShow={handleActivateNextStepModal}
                        handleConfirm={() =>
                            handleTicketStepChange(userId, activateNextStepModal, () => {
                                setStepsActiveTabKey(activateNextStepModal);
                                setActivateNextStepModal(0);
                            })
                        }
                    >
                        <h5>
                            Users
                            <Form.Select name="userId" value={userId} onChange={(e) => setUserId(e.target.value)}>
                                <option value="">Select User</option>
                                {users.filter(u => u.active).map((user, index) => (
                                    <option key={index} value={user.id}>{`${user.firstName} ${user.lastName}`}</option>
                                ))}
                            </Form.Select>
                        </h5>
                    </ConfirmationModal>
                )}
                <Row>
                    <Col xl={currentStepActive ? 8 : 12} md={12}>
                        {questions.length > 0 && (
                            <div className="mb-2">
                                {questions.map((question, index) => {
                                    return (
                                        <div key={index} className="mb-1">
                                            {question.ticketStepQuestion.type === 0 ? (
                                                <>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <b>{question.ticketStepQuestion.description}</b>
                                                    </div>
                                                    <Form.Control
                                                        type="text"
                                                        name={`answer-${index}`}
                                                        autoComplete="off"
                                                        onBlur={() => handleAnswerBlur(question.id, index)}
                                                        onChange={(e) =>
                                                            handleAnswerChange(question.id, e.target.value, index)
                                                        }
                                                        value={question.answer || ""}
                                                    />
                                                    {question.userId ? (<small className="d-flex align-self-center">By&nbsp;<b>{usersMap[question.userId].firstName}</b>&nbsp;at&nbsp;{getFormattedDateTimeStrV3(question.updatedAt)}</small>) : null}
                                                </>
                                            ) : (
                                                <>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <Form.Check
                                                            type="checkbox"
                                                            style={{ width: "fit-content" }}
                                                            id={`${step.id}-answer-${index}`}
                                                            label={question.ticketStepQuestion.description}
                                                            onChange={(e) =>
                                                                handleAnswerChange(question.id, e.target.checked, index)
                                                            }
                                                            checked={question.answer === "true"}
                                                        />

                                                    </div>
                                                    {question.userId ? (<small className="d-flex align-self-center">By&nbsp;<b>{usersMap[question.userId].firstName}</b>&nbsp;at&nbsp;{getFormattedDateTimeStrV3(question.updatedAt)}</small>) : null}
                                                </>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </Col>
                    {currentStepActive && (
                        <Col xl={4} md={12}>
                            <div
                                style={{ display: "flex", flexDirection: "column", marginRight: 10, paddingBottom: 5 }}
                            >
                                <Button
                                    variant="secondary"
                                    style={{ width: "100%" }}
                                    onClick={() => markStepAsCompleted(step.id)}
                                    disabled={disableCSB}
                                  >
                                    {disableCSB && (
                                        <Spinner animation="border" style={{ height: 13, width: 13, marginRight: 5 }} />
                                    )}
                                    Complete Step
                                </Button>
                                <Button
                                    variant="secondary"
                                    style={{ width: "100%", marginTop: 5 }}
                                    onClick={() => setStepAssigneeModal(true)}
                                >
                                    Change Step Assignee
                                </Button>
                            </div>
                        </Col>
                    )}
                </Row>
                <div className="mb-3">
                  <NotesAndMemos isMemo ticketId={ticketInfo.id} ticketStepId={step.id} />
                </div>
                {step.ticketStepTypeId === 4 && <div className="mb-3">
                  <Reviews ticket={ticketInfo} />
                </div>}
                {(allStepsCompleted || !currentStep || step.ticketStepTypeId !== currentStep.ticketStepTypeId) && (
                    <div className="d-flex justify-content-center">
                        <Button
                            style={{ width: 148 }}
                            variant="secondary"
                            onClick={() =>
                                !allStepsCompleted
                                    ? handleTicketStepChange("", step.ticketStepTypeId)
                                    : setConfirmModalShow(true)
                            }
                        >
                            Activate Step
                        </Button>
                    </div>
                )}
                {/* <StepHistory
                        show={historyModal}
                        questions={questions}
                        ticketStatusHistory={ticketStatusHistory}
                        stepHistory={stepHistory}
                        onClose={() => showHistoryModal(false)}
                        loading={sHistoryLoading}
                    /> */}
                {!step.microsoftTeamsConversationId && !chatConversationId ? (
                    <>
                        <hr />
                        <Button variant="secondary" disabled={createConversationLoading} onClick={createConversation}>
                            {createConversationLoading && (
                                <Spinner animation="border" style={{ height: 15, width: 15 }} />
                            )}{" "}
                            Create conversation
                        </Button>
                    </>
                ) : (
                    <span />
                )}
            </CustomCard>
        </div>
    );
}

export default SubStep;